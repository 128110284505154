import React from 'react';
import { Box, AppBar, Toolbar, Typography, Tabs, Tab} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Orders from './Orders';
import Clients from './Clients';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      p={2}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: "border-box",
    backgroundColor: theme.palette.grey["200"],
    overflow: "auto",
    paddingTop: 64,
  },
}));

const Home = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box height="100vh" className={classes.root}>
      <AppBar position="fixed">
        <Box mt={2} />
        <Toolbar variant="dense">
          <Typography variant="h6">
            Винаров Ауто
          </Typography>
          <Box mr={3} />
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Поръчки" />
            <Tab label="Клиенти" />
          </Tabs>
        </Toolbar>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Orders />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Clients />
      </TabPanel>
    </Box>
  );
}

export default Home;