import React, {useState, useCallback} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import info from '../constants/info.json';
import { useLogin, useFormData, useSubmit } from '../hooks/login';

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        AutoCard
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login(): JSX.Element {
    const classes = useStyles();

    const [data, handleDataChange] = useFormData({email: "", password: ""});

    const [isLoaded, login] = useLogin();

    const handleLogin = useSubmit(() => {
      login(data["email"], data["password"]);
    },[login, data]);
  
    return (
      <Box component="main" display="flex" flexDirection="column" height="100vh" justifyContent="center">
      <Container maxWidth="xs" >
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Вход
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleLogin}>
            <TextField
              variant="outlined"
              margin="normal"
              value={data["email"]}
              onChange={handleDataChange("email")}
              required
              fullWidth
              id="email"
              label="Е-мейл"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              value={data["password"]}
              onChange={handleDataChange("password")}
              required
              fullWidth
              name="password"
              label="Парола"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Вписване
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href={`mailto:${info.EMAIL}?subject=Забравена парола`} variant="body2">
                  {"Забравена парола?"}
                </Link>
              </Grid>
              <Grid item>
                <Link  href={`mailto:${info.EMAIL}?subject=Създаване на акаунт`}  variant="body2">
                  {"Нямате акаунт? Свържете се с нас."}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
        </Container>
      </Box>
    );
  }