import { useState, useCallback, useContext } from 'react';
import { FirebaseAuthContext } from '../features/firebase/FirebaseAuthProvider';
import Firebase from "../features/firebase/Firebase";
import { VoidCallback } from './login';

type CallbackFunction<T, F> = (data: T) => F;

const LIMIT = 20;

export interface Filter {
  reg?: string;
}

export const useCount = (): [any, VoidCallback] => {
  const [order, setOrder] = useState<any>();
  const { uid } = useContext(FirebaseAuthContext);

  const collection = `/user/${uid}/orders`;

  const load = useCallback(async () => {
    let data;
    try {
      data = await Firebase.firestore.collection(collection).orderBy('id', 'desc').limit(1).get();
    } catch (e) {
      console.log('You are offline', e);
    }
    setOrder(data?.docs[0].data());
  }, []);

  return [order, load];
};

export const useOrders = (): [any[], (filter?: Filter) => void, CallbackFunction<any, Promise<void>>, CallbackFunction<any, Promise<void>>, Boolean, (id: String, filter?: Filter) => void] => {
  const [orders, setOrders] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const { uid } = useContext(FirebaseAuthContext);

  const collection = `/user/${uid}/orders`;

  const load = useCallback(async (filter?: Filter) => {
    setHasMore(true);
    let data;
    let ref: any = Firebase.firestore.collection(collection);
    try {
      if (filter && filter.reg) {
        console.log("add filter:", filter);
        ref = ref.where('reg', '==', filter.reg);
      }
      data = await ref.orderBy('id', 'desc').limit(LIMIT).get();
    } catch (e) {
      console.log('You are offline', e);
    }
    const orders: any[] = data ? data.docs.map((doc: any) => ({ ...doc.data(), _id: doc.id, })) : [];
    setOrders(orders);
  }, []);

  const create = useCallback(async ({ _id, ...data }) => {
    await Firebase.firestore.collection(collection).add({
      ...data,
      createdAt: Firebase.FieldValue.serverTimestamp(),
      updatedAt: Firebase.FieldValue.serverTimestamp(),
    });
    load();
  }, []);

  const update = useCallback(async ({ _id, createdAt, updatedAt, ...data }) => {
    await Firebase.firestore.doc(`${collection}/${_id}`).update({
      ...data,
      updatedAt: Firebase.FieldValue.serverTimestamp(),
    });
    load();
  }, []);

  const loadMore = useCallback(async (id: String, filter?: Filter) => {
    let data;
    let ref: any = Firebase.firestore.collection(collection);
    try {
      if (filter && filter.reg) {
        console.log("add filter:", filter);
        ref = ref.where('reg', '==', filter.reg);
      }
      data = await ref.orderBy('id', 'desc').startAfter(id).limit(LIMIT).get();
    } catch (e) {
      console.log('You are offline', e);
    }
    if (data.empty) {
      setHasMore(false);
      return;
    }
    const orders: any[] = data ? data.docs.map((doc: any) => ({ ...doc.data(), _id: doc.id, })) : [];
    setOrders((prev) => [...prev, ...orders]);
  }, []);

  return [orders, load, create, update, hasMore, loadMore]
};

type LoadFunction = (id: string) => void;

export const useOrder = (): [any, LoadFunction] => {
  const [order, setOrder] = useState<any>(null);
  const { uid } = useContext(FirebaseAuthContext);

  const collection = `/user/${uid}/orders`;

  const load = useCallback(async (id: string) => {
    try {
      console.log(collection, id);
      const d = await Firebase.firestore.collection("user").doc(uid).collection("orders").doc(id).get();
      console.log(d?.data());
      setOrder(d?.data());
    } catch (e) {
      console.log('You are offline', e);
    }
  }, []);

  return [order, load];
};