// @flow
import React, { ReactNode, useEffect, useState, createContext } from "react";

import Firebase from "./Firebase";

const defaultFirebaseContext = {
    authStatusReported: false,
    isUserSignedIn: false,
    uid: "",
};

export const FirebaseAuthContext = createContext(defaultFirebaseContext);

const FirebaseAuthProvider = ({children}: {children: ReactNode}) => {
    const [user, setUser] = useState<any>(null);
    const [authStatusReported, setAuthStatusReported] = useState(false);

    useEffect(() => {
        Firebase.auth.onAuthStateChanged(user => {
            setUser(user!);
            setAuthStatusReported(true);
        });
    },[]);
    return (
        <FirebaseAuthContext.Provider value={{isUserSignedIn: !!user, authStatusReported, uid: user?.uid}}>
            {authStatusReported && children}
        </FirebaseAuthContext.Provider>
    );
};

export default FirebaseAuthProvider;