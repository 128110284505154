import React, { Fragment, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PrintIcon from '@material-ui/icons/Print';
import { useParams } from "react-router-dom";
import { useOrder } from "../hooks/orders";
import { usePrint } from "../hooks/print";

const Print = () => {
  let { id } = useParams<{ id: string }>();
  const [order, load] = useOrder();

  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id]);

  const [iframe, download, loading] = usePrint(order);

  return (order ? (<>
    <Box position="absolute" display={"flex"} flexDirection={"column"} bottom={18} right={38}>
      <Tooltip title="Принтирай" aria-label="print">
        <Fab color="primary" aria-label="print" onClick={() => iframe.current.contentWindow.print()}>
          <PrintIcon />
        </Fab>
      </Tooltip>
      <Box mt={2} />
      <Tooltip title="Изтегли" aria-label="download">
        <Fab color="primary" aria-label="download" onClick={() => !loading && download()}>
          {loading ? <CircularProgress size={20} color={"secondary"} /> : <CloudDownloadIcon />}
        </Fab>
      </Tooltip>
    </Box>
    <iframe ref={iframe} src="/template.html" frameBorder="0" style={{ display: "block", width: "100vw", height: "100vh" }} ></iframe>
  </>) : <div>Loading...</div>);
};

export default Print;