import React, { ReactNode } from "react";
import { Redirect } from 'react-router-dom';
import { FirebaseAuthContext } from './FirebaseAuthProvider';
import routes from '../../constants/routes.json';

const ProtectedScreen = ({children}: {children: ReactNode}) => {
    return (
        <FirebaseAuthContext.Consumer>
            {
                ({isUserSignedIn}) => {
                    if (isUserSignedIn) {
                        return children;
                    }
                    return <Redirect to={routes.LOGIN} />;
                }
            }
        </FirebaseAuthContext.Consumer>
    );
};

export default ProtectedScreen;