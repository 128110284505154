import React from 'react';
import { BrowserRouter as Router, } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Routes from '../Routes';

const theme = createMuiTheme({
  overrides: {
    MuiDialogTitle: {
      root: {
        '& h6': {
          color: '#000'
        }
      }
    }
  }
});

type Props = {
  history: History;
};

const Root = () => (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
);

export default Root;
