import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Fab, Box } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormData, useSubmit } from '../hooks/login';
import { useClients } from '../hooks/clients';

const useStyles = makeStyles({
    root: {
      marginBottom: 70,
    },
    table: {
      minWidth: 650,
    },
    form: {
      display: 'block',
      margin: 'auto',
      width: 'fit-content',
    },
  },
);

const EMPTY = {
  _id: "",
  reg: "",
  model: "",
  vin: "",
  km: "",
  owner: "",
  phone: "",
  email: "",
};

export type Client = {
  _id: string,
  reg: string,
  model: string,
  vin: string,
  km: string,
  owner: string,
  phone: string,
  email: string,
};

const Clients = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [data, handleDataChange, reset, init] = useFormData({...EMPTY});

  const [clients, load, create, update] = useClients();

  useEffect(() => {
    load();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const openClient = (id: string) => {
    console.log(id);
    const selected = clients.find((o) => o._id === id);
    console.log(selected);
    init(selected);
    setOpen(true);
  };

  const handleCreate = useSubmit(async () => {
    console.log(data);
    if (data._id) {
      update(data);
    }else {
      create(data);
    }
    handleClose();
  }, [data]);

  return (
    <>
      <TableContainer className={classes.root} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Рег. №</TableCell>
              <TableCell>Автомобил</TableCell>
              <TableCell>Номер на шаси</TableCell>
              <TableCell>Километраж</TableCell>
              <TableCell>Собственик</TableCell>
              <TableCell>Телефон</TableCell>
              <TableCell>Е-мейл</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((row) => (
              <TableRow hover key={row.reg} onClick={() => openClient(row._id)}>
                <TableCell>{row.reg}</TableCell>
                <TableCell>{row.model}</TableCell>
                <TableCell>{row.vin}</TableCell>
                <TableCell>{Number(row.km).toLocaleString('bg-BG')} km</TableCell>
                <TableCell>{row.owner}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box position="absolute" bottom={18} right={18}>
        <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
          <AddIcon />
        </Fab>
      </Box>
      <Dialog
        open={open}
        maxWidth={'xl'}
        onClose={handleClose}
      >
        <form className={classes.form} noValidate onSubmit={handleCreate}>
          <DialogTitle>{"Добавяне на клиент"}</DialogTitle>
          <DialogContent>
            <Box width={700} overflow="hidden">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="reg"
                    label="Регистрационнен номер"
                    value={data["reg"]}
                    onChange={handleDataChange("reg")}
                    name="reg"
                    autoComplete="reg"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="model"
                    label="Автомобил"
                    value={data["model"]}
                    onChange={handleDataChange("model")}
                    name="model"
                    autoComplete="model"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="vin"
                    label="Номер на шаси"
                    value={data["vin"]}
                    onChange={handleDataChange("vin")}
                    name="vin"
                    autoComplete="vin"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="km"
                    label="Километраж"
                    value={data["km"]}
                    onChange={handleDataChange("km")}
                    name="km"
                    autoComplete="km"
                  />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="owner"
                    label="Собственик"
                    value={data["owner"]}
                    onChange={handleDataChange("owner")}
                    name="owner"
                    autoComplete="owner"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="Телефон"
                    value={data["phone"]}
                    onChange={handleDataChange("phone")}
                    name="phone"
                    autoComplete="phone"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Е-мейл"
                    value={data["email"]}
                    onChange={handleDataChange("email")}
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box px={2} py={1} display={"flex"} flexDirection={'row'}>
              <Button onClick={handleClose} color="primary">
                Прекрати
              </Button>
              <Box ml={2} />
              <Button type="submit" color="primary" variant="contained">
                {data._id ? 'Обнови' : 'Добави'}
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default Clients;