import {useState, useCallback, DependencyList} from 'react';
import Firebase from "../features/firebase/Firebase";

export const useLogin = (): [boolean, (email: string, password: string) => void] => {
    const [isLoaded, setIsLoaded] = useState(false);

    const login = useCallback(async (email: string, password: string) => {
        await Firebase.auth.signInWithEmailAndPassword(email, password);
        setIsLoaded(true);
    },[]);

    return [isLoaded, login];
};

type Data = {[key:string]: any};
export type VoidCallback = () => void;
export type Callback<T> = (data: T) => void;
export type CallbackFunction<T> = (data: T) => (event: React.ChangeEvent<{ value: unknown }>) => void;

export const useFormData = (initData: Data, onChange?: VoidFunction): [Data, CallbackFunction<string>, VoidCallback, Callback<any>] => {
    const [data, setData] = useState<Data>(initData);

    const handleChange = useCallback(
        (name: string) => (
            event: React.ChangeEvent<{ value: unknown }>
        ) => {
            const value = event.target.value;
            setData((prev) => ({
                ...prev,
                [name]: value,
            }));
        },
        [onChange, data]
    );

    const reset = useCallback(
        () => {
            setData(initData);
        },
        [initData]
    );

    const init = useCallback((d) => {
        setData(d);
    },[]);

    return [data, handleChange, reset, init];
}

export type SubmitFunction = (e: React.FormEvent<HTMLFormElement>) => void;

export const useSubmit = <T extends (...args: any[]) => any>(
    callback: T,
    deps: DependencyList
  ): SubmitFunction =>
    useCallback(
      (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        callback(e);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [callback, ...deps]
    );
