import React, { Fragment, useEffect, useState, useCallback, useMemo } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Fab, Box, Typography, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';

import { useCount, useOrders } from '../hooks/orders';
import { Filter } from '../hooks/orders';
import { useClients } from '../hooks/clients';
import { useSubmit } from '../hooks/login';
import { usePrint } from '../hooks/print';
import { sum } from '../utils/array';
import { pad, format } from '../utils/date';
import { Client } from './Clients';

const GridNoGutter = withStyles({
  root: {
    paddingBottom: '0px !important'
  }
})(Grid);

const GridClearGutter = withStyles({
  root: {
    paddingBottom: '0px !important',
    paddingTop: '0px !important'
  }
})(Grid);

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
  },
  table: {
    minWidth: 650,
  },
  form: {
    display: 'block',
    margin: '20px',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  saveButton: {
    color: "#fff"
  }
},
);

const EMPTY_CLIENT = {
  _id: "",
  reg: "",
  model: "",
  vin: "",
  km: "",
  owner: "",
  phone: "",
  email: "",
};

type Operation = {
  name: string,
  worker: string,
  hours: string,
  value: string
};

type Material = {
  id: string,
  name: string,
  count: string,
  value: string,
};

type Order = {
  _id: string,
  id: string,
  reg: string,
  model: string,
  vin: string,
  km: string,
  owner: string,
  phone: string,
  email: string,
  staffing: string,
  description: string,
  createdAt: any,
  finding: string,
  operations: Operation[],
  materials: Material[],
  status: string,
};

const EMPTY_ORDER = {
  _id: "",
  id: "",
  reg: "",
  model: "",
  vin: "",
  km: "",
  owner: "",
  phone: "",
  email: "",
  staffing: "",
  description: "",
  finding: "",
  createdAt: null,
  operations: [
    {
      name: "",
      worker: "",
      hours: "",
      value: ""
    }
  ],
  materials: [
    {
      id: "",
      name: "",
      count: "",
      value: "",
    }
  ],
  status: 'incomplete'
};


const Orders = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [sideOpen, setSideOpen] = useState(false);
  const [client, setClient] = useState<Client>({ ...EMPTY_CLIENT });
  const [order, setOrder] = useState<Order>({ ...EMPTY_ORDER });
  const [count, loadCount] = useCount();

  const [orders, load, create, update, hasMore, loadMore] = useOrders();
  const [clients, loadClients, , updateClient] = useClients();

  const table = useMemo(() => {
    console.log(orders);
    return orders.map(({ _id, id, reg, createdAt, owner, operations, materials, status }, i) => ({
      _id,
      id,
      reg,
      createdAt: format(createdAt.toDate()),
      owner,
      operations: operations.map(({ name }: { name: string }) => name).join(', '),
      value: `${(sum(operations, 'value', 'hours') + sum(materials, 'value', 'count')).toFixed(2)}лв`,
      statusText: status === "incomplete" ? 'В процес' : 'Завършена',
      status,
    }));
  }, [orders]);

  useEffect(() => {
    load();
    loadCount();
    loadClients();
  }, []);

  const openOrder = useCallback((id: string) => {
    console.log(id);
    const selected = orders.find((o) => o._id === id);
    const selectedClient = clients.find((o) => o.reg === selected.reg);
    console.log(selected, selectedClient);
    setOrder(selected);
    setClient(selectedClient);
    setOpen(true);
  }, [orders, clients]);

  const handleClickOpen = () => {
    setOrder({
      ...EMPTY_ORDER,
      id: pad((count ? parseInt(count.id) : 0) + 1, 5),
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClientChange = (event: any, newValue: any) => {
    const c = newValue || EMPTY_CLIENT;
    const { reg, model, vin, km, owner, phone, email } = c;

    console.log('handleClient change');

    setClient(c);
    setOrder((o) => {
      return {
        ...o,
        reg, model, vin, km, owner, phone, email,
      }
    })
  };

  const handleAddOperation = () => {
    setOrder((o) => ({
      ...o,
      operations: [
        ...o.operations,
        {
          name: "",
          worker: "",
          hours: "0",
          value: ""
        }
      ]
    }));
  };

  const handleAddMaterial = () => {
    setOrder((o) => ({
      ...o,
      materials: [
        ...o.materials,
        {
          id: "",
          name: "",
          count: "1",
          value: "",
        }
      ]
    }));
  };

  const handleInputChange = useCallback(
    (name: string) => (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      const value = event.target.value;
      const [prop, index, inner] = name.split('.');

      const p = prop as "operations" | "materials";

      console.log(prop, index, inner);

      setOrder((prev) => {
        const d = prev[p] as { [key: string]: any }[];
        console.log(d);

        d[Number(index)] = {
          ...d[Number(index)],
          [inner]: value,
        };

        return {
          ...prev,
          [p]: d
        };
      });
    },
    []
  );

  const handleChange = useCallback((field: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
    const value: string = event.target.value as string;

    if (field === "km") {
      setClient((prev) => ({
        ...prev,
        km: value,
      }));
    }

    setOrder((prev) => ({
      ...prev,
      [field]: value,
    }));
  }, []);

  const handleCreate = useSubmit(() => {
    console.log(order, client);
    if (order._id) {
      update(order)
    } else {
      create(order);
      loadCount();
    }
    if (client) {
      updateClient(client);
    }
    handleClose();
  }, [create, order, client]);

  const handleComplete = useCallback(() => {
    update({
      ...order,
      status: 'complete'
    });
    //showPrint();
    window.open(`/print/${order._id}`, '_blank');
  }, [order, /* showPrint, */ update]);

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent,) => {
    setSideOpen((open) => !open);
  };

  const handleLoadMore = (event: any) => {
    loadMore(orders[orders.length - 1].id, filter);
  };

  const [filter, setFilter] = useState<Filter>({});

  const handleRegFilter = (event: any, newValue: any) => {
    if (newValue != null) {
      setFilter({ reg: newValue.reg });
    } else {
      setFilter({ reg: undefined });
    }
  };

  useEffect(() => {
    if (filter && filter.hasOwnProperty('reg')) {
      console.log(filter);
      load(filter);
    }
  }, [filter]);

  const duplicate = useCallback((id: string) => {
    const { _id, createdAt, updatedAt, ...selected } = orders.find((o) => o._id === id);
    const selectedClient = clients.find((o) => o.reg === selected.reg);
    setOrder({
      ...selected,
      status: 'incomplete',
      id: pad((count ? parseInt(count.id) : 0) + 1, 5),
    });
    setClient(selectedClient);
    setOpen(true);
  }, [orders, clients, count]);;

  return (
    <>
      <Box width={200}>
        <Autocomplete
          style={{ marginTop: 16, marginBottom: 16 }}
          options={clients}
          value={filter.reg}
          inputValue={filter.reg}
          getOptionLabel={(option) => typeof option === 'string' ? option : option.reg}
          onChange={handleRegFilter}
          renderInput={(params) => <TextField {...params} size="small" label="Рег. номер" variant="outlined" />}
        />
      </Box>
      <TableContainer className={classes.root} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Дата</TableCell>
              <TableCell>Рег. №</TableCell>
              <TableCell>Собственик</TableCell>
              <TableCell>Извършени дейности</TableCell>
              <TableCell align="right">Стойност</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {table.map((row) => (
              <TableRow hover key={row._id} onClick={() => openOrder(row._id)}>
                <TableCell component="th" scope="row">{row.id}</TableCell>
                <TableCell>{row.createdAt.toString()}</TableCell>
                <TableCell>{row.reg}</TableCell>
                <TableCell>{row.owner}</TableCell>
                <TableCell>{row.operations}</TableCell>
                <TableCell align="right">{row.value}</TableCell>
                <TableCell><Chip color={row.status === 'incomplete' ? "secondary" : "primary"} size="small" label={row.statusText} /></TableCell>
                <TableCell>
                  <Tooltip title="Създаване на подобна" aria-label="download">
                    <IconButton onClick={(e) => { e.stopPropagation(); duplicate(row._id); }}><FileCopyIcon /></IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {hasMore && <Box display={"flex"} alignItems={"center"} justifyContent={"center"}><Button onClick={handleLoadMore}>{"Зареди още"}</Button></Box>}
      <Box position="absolute" bottom={18} right={18}>
        <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
          <AddIcon />
        </Fab>
      </Box>
      <Dialog
        open={open}
        fullScreen
        maxWidth={'xl'}
        onClose={handleClose}
      >
        <AppBar position="fixed">
          <Box mt={2} />
          <Toolbar variant="dense">
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              Поръчка номер #{order.id}
            </Typography>
            <Box flex={1} />
            <Button onClick={toggleDrawer} variant="contained">
              Диагностика
            </Button>
          </Toolbar>
        </AppBar>
        <Box pt={8} />
        <form className={classes.form} noValidate onSubmit={handleCreate}>
          <Drawer anchor={"right"} open={sideOpen} onClose={toggleDrawer}>
            <Box width={500} p={4}>
              <Typography variant="h6">Диагностика</Typography>
              <Box pt={2} />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="staffing"
                label="Окомплектоване"
                value={order.staffing}
                onChange={handleChange('staffing')}
                multiline
                rows={6}
                size="small"
                name="staffing"
                autoComplete="staffing"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="description"
                label="Описание на повредите и/или желан ремонт според клиента"
                value={order.description}
                onChange={handleChange('description')}
                multiline
                rows={6}
                size="small"
                name="description"
                autoComplete="description"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="finding"
                label="Констатация за причината или открити неизправности"
                value={order.finding}
                onChange={handleChange('finding')}
                multiline
                rows={6}
                size="small"
                name="finding"
                autoComplete="finding"
              />
            </Box>
          </Drawer>
          <Grid container spacing={3}>
            <>
              <GridNoGutter item xs={12} sm={2}>
                <Autocomplete
                  style={{ marginTop: 16 }}
                  options={clients}
                  value={order.reg}
                  inputValue={order.reg}
                  getOptionLabel={(option) => typeof option === 'string' ? option : option.reg}
                  onChange={handleClientChange}
                  renderInput={(params) => <TextField {...params} size="small" label="Клиент" variant="outlined" />}
                />
              </GridNoGutter>
              <GridNoGutter item xs={12} sm={2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="reg"
                  disabled={order.reg === ""}
                  label="Километраж"
                  value={order.km}
                  onChange={handleChange('km')}
                  size="small"
                  name="km"
                  autoComplete="km"
                />
              </GridNoGutter>
              <GridNoGutter item xs={12} sm={2}>
                <ListItem dense>
                  <ListItemText primary="Автомобил" secondary={order.model || "------"} />
                </ListItem>
              </GridNoGutter>
              <GridNoGutter item xs={12} sm={2}>
                <ListItem dense>
                  <ListItemText primary="Номер на шаси" secondary={order.vin || "------"} />
                </ListItem>
              </GridNoGutter>
              <GridNoGutter item xs={12} sm={2}>
                <ListItem dense>
                  <ListItemText primary="Собственик" secondary={order.owner || "------"} />
                </ListItem>
              </GridNoGutter>
              <GridNoGutter item xs={12} sm={2}>
                <ListItem dense>
                  <ListItemText primary="Телефон" secondary={order.phone || "------"} />
                </ListItem>
              </GridNoGutter>
            </>
            <>
              <GridNoGutter item xs={12}>
                <Typography variant="h6">Извършени операции</Typography>
              </GridNoGutter>
              {order.operations.map((operation, index) => (
                <Fragment key={index}>
                  <GridClearGutter item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Операция"
                      name="name"
                      value={operation["name"]}
                      onChange={handleInputChange(`operations.${index}.name`)}
                      size="small"
                      autoFocus
                      autoComplete="name"
                    />
                  </GridClearGutter>
                  <GridClearGutter item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Извършил"
                      name="worker"
                      value={operation["worker"]}
                      onChange={handleInputChange(`operations.${index}.worker`)}
                      size="small"
                      autoComplete="worker"
                    />
                  </GridClearGutter>
                  <GridClearGutter item xs={12} sm={2}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Работни часове"
                      name="hours"
                      value={operation["hours"]}
                      onChange={handleInputChange(`operations.${index}.hours`)}
                      size="small"
                      autoComplete="hours"
                    />
                  </GridClearGutter>
                  <GridClearGutter item xs={12} sm={2}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Стойност"
                      value={operation["value"]}
                      onChange={handleInputChange(`operations.${index}.value`)}
                      name="value"
                      size="small"
                      autoComplete="value"
                    />
                  </GridClearGutter>
                </Fragment>
              ))}
              <GridNoGutter item xs={6}>
                <Chip label={<Typography>Общо: <strong>{sum(order.operations, 'value', 'hours').toFixed(2)}лв</strong></Typography>} />
              </GridNoGutter>
              <GridNoGutter item xs={6}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Button startIcon={<AddIcon />} variant="outlined" color="primary" onClick={handleAddOperation}>
                    Добави
                  </Button>
                </Box>
              </GridNoGutter>
            </>
            <>
              <GridNoGutter item xs={12}>
                <Typography variant="h6">Вложени материали и части</Typography>
              </GridNoGutter>
              {order.materials.map((material, index) => (
                <Fragment key={index}>
                  <GridClearGutter item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Номер на част"
                      name="id"
                      value={material["id"]}
                      onChange={handleInputChange(`materials.${index}.id`)}
                      size="small"
                      autoFocus
                      autoComplete="id"
                    />
                  </GridClearGutter>
                  <GridClearGutter item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Наименование"
                      name="name"
                      value={material["name"]}
                      onChange={handleInputChange(`materials.${index}.name`)}
                      size="small"
                      autoComplete="name"
                    />
                  </GridClearGutter>
                  <GridClearGutter item xs={12} sm={1}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Бр."
                      name="count"
                      value={material["count"]}
                      onChange={handleInputChange(`materials.${index}.count`)}
                      size="small"
                      autoComplete="count"
                    />
                  </GridClearGutter>
                  <GridClearGutter item xs={12} sm={2}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Стойност"
                      name="value"
                      value={material["value"]}
                      onChange={handleInputChange(`materials.${index}.value`)}
                      size="small"
                      autoComplete="value"
                    />
                  </GridClearGutter>
                  <GridClearGutter item xs={12} sm={2}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      inputProps={{
                        readOnly: true
                      }}
                      fullWidth
                      label="Общо"
                      value={((Number(material["value"]) * Number(material["count"])) || 0).toFixed(2)}
                      name="total"
                      size="small"
                      autoComplete="total"
                    />
                  </GridClearGutter>
                </Fragment>
              ))}
              <GridNoGutter item xs={6}>
                <Chip label={<Typography>Общо: <strong>{sum(order.materials, 'value', 'count').toFixed(2)}лв</strong></Typography>} />
              </GridNoGutter>
              <GridNoGutter item xs={6}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Button startIcon={<AddIcon />} variant="outlined" color="primary" onClick={handleAddMaterial}>
                    Добави
                  </Button>
                </Box>
              </GridNoGutter>
            </>
          </Grid>
          <Box pt={8} />
          <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar variant="dense">
              <Typography variant="h6">Стойност на поръчката: {(sum(order.operations, 'value', 'hours') + sum(order.materials, 'value', 'count')).toFixed(2)}лв</Typography>
              <Box flex={1} />
              <Button type="submit" className={classes.saveButton} startIcon={<SaveIcon />} disableElevation >
                Запиши
              </Button>
              <Box pl={2} />
              <Button onClick={handleComplete} disabled={!order._id} startIcon={<PrintIcon />} variant="contained">
                Завърши
              </Button>
            </Toolbar>
          </AppBar>
        </form>
      </Dialog>
    </>
  );
}

export default Orders;