import {useState, useCallback, useContext} from 'react';
import { FirebaseAuthContext } from '../features/firebase/FirebaseAuthProvider';
import Firebase from "../features/firebase/Firebase";
import { VoidCallback } from './login';

type CallbackFunction<T, F> = (data: T) => F;

export const useClients = (): [any[], VoidCallback, CallbackFunction<any, Promise<void>>,  CallbackFunction<any, Promise<void>>] => {
  const [clients, setClients] = useState<any[]>([]);
  const {uid} = useContext(FirebaseAuthContext);

  const collection = `/user/${uid}/clients`;

  const load = useCallback(async () => {
    let data;
    try {
      data = await Firebase.firestore.collection(collection).orderBy('createdAt', 'desc').get();
    }catch(e) {
      console.log('You are offline');
    }
    console.log(data);
    const clients: any[] = data ? data.docs.map((doc) => ({_id: doc.id, ...doc.data()})) : [];
    setClients(clients);
  }, []);

  const create = useCallback(async ({_id, ...data}) => {
    await Firebase.firestore.collection(collection).add({
      ...data,
      createdAt: Firebase.FieldValue.serverTimestamp(),
      updatedAt: Firebase.FieldValue.serverTimestamp(),
    });
    load();
  }, []);

  const update = useCallback(async ({_id, createdAt, updatedAt, ...data}) => {
    await Firebase.firestore.doc(`${collection}/${_id}`).update({
      ...data,
      updatedAt: Firebase.FieldValue.serverTimestamp(),
    });
    load();
  }, []);

  return [clients, load, create, update]
};