/* eslint react/jsx-props-no-spreading: off */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from './constants/routes.json';
import App from './containers/App';
import HomePage from './containers/HomePage';
import PrintPage from './containers/PrintPage';
import LoginPage from './containers/LoginPage';
import ProtectedScreen from './features/firebase/ProtectedScreen';

export default function Routes() {
  return (
    <App>
      <Switch>
        <Route path={routes.LOGIN} exact component={LoginPage} />
        <ProtectedScreen>
          <Route path={routes.HOME} exact component={HomePage} />
          <Route path={routes.PRINT} exact component={PrintPage} />
        </ProtectedScreen>
      </Switch>
    </App>
  );
}
