import React from 'react';
import Login from '../components/Login';
import { Redirect } from 'react-router-dom';
import { FirebaseAuthContext } from '../features/firebase/FirebaseAuthProvider';
import routes from '../constants/routes.json';

export default function LoginPage() {
  return (<FirebaseAuthContext.Consumer>
            {
                ({isUserSignedIn}) => {
                    if (isUserSignedIn) {
                        return <Redirect to={routes.HOME} />;
                    }
                    return <Login />;
                }
            }
        </FirebaseAuthContext.Consumer>);
}
