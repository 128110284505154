import { useCallback, useEffect, useRef, useState } from "react";

export const usePrint = (order: any): [any, Function, boolean] => {
  const iframe = useRef<HTMLIFrameElement>(null);
  const [loading, setLoading] = useState(false);

  const download = useCallback(async () => {
    setLoading(true);
    let html = "";
    if (iframe.current) {
      const doc = iframe.current.contentDocument || iframe.current.contentWindow?.document;
      if (doc) {
        html = doc.documentElement.outerHTML;
      }
    }

    const response = await fetch("https://us-central1-autocard-ea14b.cloudfunctions.net/renderPDF", {
      method: 'post',
      mode: 'cors',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ html }),
    });
    console.log("load print", response);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    console.log(url);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `Поръчка №${order.id}.pdf`;
    document.body.appendChild(a);
    a.click();
    setLoading(false);
    //window.URL.revokeObjectURL(url);
  }, [order, iframe]);

  useEffect(() => {
    console.log("use effect show", order);
    if (order && iframe.current && iframe.current.contentWindow) {
      console.log("set order");
      (iframe.current.contentWindow as any).order = order;
    }
  }, [order]);

  return [iframe, download, loading];
};