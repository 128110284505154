// @flow
import firebase from "firebase/app"
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

const config = {
    apiKey: "AIzaSyBmDEBLn28DBp_SS1bJDFKI_eDw3qb-DyQ",
    authDomain: "autocard-ea14b.firebaseapp.com",
    databaseURL: "https://autocard-ea14b.firebaseio.com",
    projectId: "autocard-ea14b",
    storageBucket: "autocard-ea14b.appspot.com",
    messagingSenderId: "203429163426",
    appId: "1:203429163426:web:8764f48840305f3274c2fb",
    measurementId: "G-PNP9CCX18G"
};

firebase.initializeApp(config);

console.log(firebase);

const fb = {
    auth: firebase.auth(),
    storage: firebase.storage(),
    firestore: firebase.firestore(),
    FieldValue: firebase.firestore.FieldValue,
}

export default fb;